import { createSlice } from "@reduxjs/toolkit";

const NotifyOrdersSlice = createSlice({
  name: "store slice",
  initialState: {
    notifyProductOrders: JSON.parse(localStorage.getItem("notifyOrders")) ?? {},
    playSound: false,
  },
  reducers: {
    setNotifyOrders(state, action) {
      state.notifyProductOrders = {
        ...state.notifyProductOrders,
        [action.payload["order_id"]]: [],
      };
    },

    getProductItems(state, action) {
      if (
        state.notifyProductOrders[action.payload[0]]?.includes(
          action.payload[1]
        )
      ) {
        let findIndex = state.notifyProductOrders[action.payload[0]].findIndex(
          (ele) => ele == action.payload[1]
        );
        if (findIndex || findIndex == 0) {
          state.notifyProductOrders[action.payload[0]].splice(findIndex, 1);
        }
      } else {
        state.notifyProductOrders[action.payload[0]]?.push(action.payload[1]);
      }
    },

    setSoundState(state, action) {
      state.playSound = action.payload;
    }
  },
});

export const { setNotifyOrders, getProductItems, setSoundState } = NotifyOrdersSlice.actions;
export default NotifyOrdersSlice.reducer;
