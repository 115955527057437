import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChecked: false,
};

const checkboxSlice = createSlice({
  name: "checkbox",
  initialState,
  reducers: {
    setCheckbox: (state, action) => {
      state.isChecked = action.payload;
    },
  },
});

export const { setCheckbox } = checkboxSlice.actions;
export default checkboxSlice.reducer;
