import "./App.css";
import { Provider, useSelector } from "react-redux";
import Home from "./Components/Home.tsx";
import Header from "./Components/Header.tsx";
import StoreClose from "./Components/StoreClose.tsx";
import NewHome from "./Components/NewHome.tsx";
import { store } from "./Components/Redux_Store/Store";
import PageNotFound from "./Components/PageNotFound.tsx";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import AccessDenied from "./Components/AccessDenied.tsx";
import Login from './Components/Login.tsx';

const persistor = persistStore(store);

function App() {
  const queryClient = new QueryClient();
  const storeId = useSelector((state) => state.user?.user_data?.store);
  const [activeBtnName, setActiveBtnName] = useState("newOrder");
  const user = useSelector((state) => state.user.user_data);
  const searchParams = new URLSearchParams(window.location.search);
  //THIS IS A CALL-BACK FUNCTION, USE TO GET ACTIVE BUTTUN VALUE FROM HOME PAGE, THAT BUTTON IS ON ALL, CANCEL AND COMPLETE.
  // AND GET VALUE THEN PASS TO HEADER COMPONENT.
  function activeBtnState(name) {
    setActiveBtnName(name);
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: user ? <Header activeBtnName={activeBtnName} StoreId={storeId || searchParams.get('id')} /> : <Navigate to={`/login${window.location.search}`} />,
      children: [
        {
          path: "/",
          element: (
            <NewHome activeBtnName={activeBtnName} isActive={activeBtnState} />

          ),
        },
        {
          path: 'home',
          element: (
            <Home activeBtnName={activeBtnName} isActive={activeBtnState} />
          ),
        },
        {
          path: "/storeclose",
          element: <StoreClose />,
        },
        {
          path: "/access-denied",
          element: <AccessDenied />,
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
  ]);
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </PersistGate>
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
