import { createSlice } from "@reduxjs/toolkit";

const PrinterSlice = createSlice({
    name: "order",
    initialState: {
        printersList: [],
        // selectedPrinter: '659e760f5f3f838a99461393'
        selectedPrinter: ''
    },
    reducers: {
        setPrinterList(state, action) {
            state.printersList = action.payload;
        },
        setSelectedPrinter(state, action) {
            state.selectedPrinter = action.payload;
        }
    },
});

export const { setPrinterList, setSelectedPrinter } = PrinterSlice.actions;
export default PrinterSlice.reducer;