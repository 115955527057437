import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
// import { useAppSelector } from '@/redux/hooks';
// import { useSearchParams } from "react-router-dom";

export const SocketIO = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get("id");

  const socket = useMemo(
    () =>
      io(`wss://worker.loopos.ca?store=${storeId}`, {
        transports: ["websocket"],
        autoConnect: true,
      }),
    []
  );
  return socket;
};
export default SocketIO;
