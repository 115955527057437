import React from "react";
import moment from "moment-timezone";
import TakeAwayVoidDineInVoid from "./MapingSection/TakeAwayVoidDineInVoid.tsx";
import NewTakeAwayDineIn from "./MapingSection/NewTakeAwayDineIn.tsx";
import ButtonBar from "./ButtonBar.tsx";
import TableBarTwoToneIcon from "@mui/icons-material/TableBarTwoTone";
import ChairAltTwoToneIcon from "@mui/icons-material/ChairAltTwoTone";
import AlarmOnSharpIcon from "@mui/icons-material/AlarmOnSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { SocketIO } from "./Controllers/socket.tsx";
import NewCounter from "./NewCounter.tsx";
import Shimmer from "./Shimmer.tsx";
import { StoreController } from "./Controllers/store_controller.tsx";
import { ApiStatus } from "../api/api_url.ts";
import { setReloadCounter, setStore } from "./Redux_Store/Slices/StoreSlice.js";
import { GToaster } from "../helper/g_toaster.tsx";
import { addIncludeKey } from "../Helpers/Helper.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sound, textUpperCase } from "../helper/g_constants.ts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  setAllOrder,
  setAllVoidOrder,
  setCompleteOrder,
  setTotalOrder,
} from "./Redux_Store/Slices/OrderSlice.js";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { io, Socket } from "socket.io-client";

const NewHome = ({ isActive }) => {
  const [orderType, setOrderType] = useState("all");
  const [currentTab, setCurrentTab] = useState("newOrder");
  const [orderdata, setOrderData] = useState<any[]>([]);
  const [originalOrderData, setOriginalOrderData] = useState<any[]>([]); // Duplicate state
  const [loading, setLoading] = useState(false);
  const [voidOrders, setVoidOrders] = useState<any[]>([]);
  const [btnIndex, setBtnIndex] = useState<number>(0);
  const [notifyloading, setNotifyLoading] = useState(false);
  const [readyToPickLoading, setReadyToPickLoading] = useState(false);
  const { gridNum } = useSelector((state: any) => state.orders);
  const counter = useSelector((state: any) => state?.store?.reloadCounter);
  const printerId = useSelector(
    (state: any) => state?.printers?.selectedPrinter
  );
  
  const isChecked = useSelector((state: any) => state?.checkbox?.isChecked);
  const playSoundBtn = useRef<HTMLButtonElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const socket = SocketIO();
  
  const storeId = searchParams?.get("id");
  const STORE_ID = useSelector((state: any) => state.user?.user_data?.store);
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toaster = new GToaster();
  const storeControlller = new StoreController();
  const storeController = new StoreController();
  const token = useSelector((state: any) => state.user.access_token);

  const filterAccTab = ({
    name,
    myData,
    isEmpty = false,
  }: {
    myData: Array<Record<string, any>>;
    isEmpty?: Boolean;
    name?: string;
  }) => {
    let data = isEmpty
      ? []
      : myData?.length
      ? myData
      : originalOrderData?.length
      ? originalOrderData
      : [];
    let tabName = name || currentTab;
    if (tabName === "newOrder") {
      const newOrders = data?.filter(
        ({ is_new, is_pickup_ready, is_cancelled, is_completed } = {}) =>
          is_new ||
          !!(!is_new && !is_pickup_ready && (!is_cancelled || !is_completed))
      );
      setOrderData(newOrders);
      dispatch(setTotalOrder(newOrders.length));
    }
    if (tabName === "readyToPick") {
      const readyOrders = data?.filter(
        ({ is_pickup_ready }) => !!is_pickup_ready
      );
      setOrderData(readyOrders);
      dispatch(setTotalOrder(readyOrders?.length));
    }
    if (tabName === "completeOrder") {
      const completeOrders = voidOrders?.filter(
        ({ is_completed = false } = {}) => is_completed
      );
      setOrderData(completeOrders);
      dispatch(setCompleteOrder(completeOrders?.length));
    }
    if (tabName === "voidOrder") {
      const orders = voidOrders?.filter(
        ({ is_cancelled = false } = {}) => is_cancelled
      );
      setOrderData(orders);
      dispatch(setAllVoidOrder(orders?.length || 0));
    }
  };

  const handleActive = (name: string) => setOrderType(name);

  const activeNewCancelCompleteOrders = (name: string) => {
    setCurrentTab(name);
    isActive(name);
    filterAccTab({ myData: [], name });
    sessionStorage?.setItem("currentTab", name);
  };

  const getCurrentTab = () => {
    const savedTab = sessionStorage?.getItem("currentTab");
    return savedTab || currentTab;
  };

  const getStoreProfile = async () => {
    if (!storeId || !token) return;
    try {
      let response = await storeControlller?.getStoreDetails({
        _id: storeId,
        token,
      });
      if (!response?.data?.data?.store_setting?.general_setting?.kds_status)
        navigate(`/access-denied`);
      dispatch(setStore(response?.data?.data));
    } catch (error) {
      toaster?.warning({
        title: `Error fetching store profile data: ${error}`,
      });
    }
  };

  const getStoreOrdersData = async (day_id: string) => {
    setLoading(true);
    try {
      const [response1, response2] = await Promise.all([
        storeController?.getStoreOrders({
          day_id,
          store_Id: isChecked ? storeId : printerId,
          old: false,
          token,
        }),
        storeController?.getStoreVoidOrders({
          day_id,
          store_Id: isChecked ? storeId : printerId,
          token,
        }),
      ]);
      if (
        response1?.status == ApiStatus.STATUS_200 &&
        response2?.status == ApiStatus.STATUS_200
      ) {
        const responseData = response1?.data?.data;
        filterAccTab({
          myData: addIncludeKey(responseData),
          isEmpty: !!!responseData?.length,
        });
        dispatch(setAllOrder(responseData));
        // dispatch(setTotalOrder(responseData?.length));
        setVoidOrders(response2?.data?.data);
        setOriginalOrderData(responseData);
      } else {
        throw new Error("Invalid Response from server");
      }
    } catch (error) {
      toaster?.warning({ title: `Can Not Get Store Orders, ${error}` });
    } finally {
      setLoading(false);
    }
  };
const [appBarShow,setAppBar]=useState<boolean>(false)
  async function getStoreDay() {
    try {
      let response = await storeController?.getStartDay({
        _id: storeId,
        token,
      });
      if (response?.data?.data?.day_id) {
        // dispatch(changeAppBarState(false))
        setAppBar(false)

        let dayid = response?.data?.data?.day_id;

        if (!dayid) return;
        getStoreOrdersData(dayid);
      } else {
        // dispatch(changeAppBarState(true))
        // socket?.disconnect();
        setAppBar(true)
        
        // navigate("/storeclose", { replace: true }); // Prevent back navigation loop

      }
    } catch (error) {
      toaster?.warning({ title: `Can Not Get Store Day Details, ${error}` });
    }
  }

  const handlePlaySound = () => {
    sound.loop = true;
    sound.play().catch((err) => console.log(err));
  };
  // const [count, setCount] = useState<Array<Record<string,any>>>([]);
  // const gToaster = new GToaster();
  // async function getKdsCount() {
  //   let response = await storeController.getKdsCount({ token });
  //   if (response.status == ApiStatus.STATUS_200) {
  //     console.log(response, "ghost");
  //     setCount(response?.data?.data);
  //   } else if (response.status == ApiStatus.STATUS_500) {
  //     gToaster.warning({ title: "500 Server Error" });
  //   } else {
  //     gToaster.warning({ title: " Server Error" });
  //   }
  // }
  // console.log(count, "djsk");
  // useEffect(() => {
  //   getKdsCount();
  // }, []);
  const checkIfNewOrder = (ele: Record<string, any>) => {
    const productsArray = [...(ele?.take_away ?? []), ...(ele?.dine_in ?? [])];
    const isInclude = productsArray
      ?.map(({ product }) => product)
      ?.flat()
      ?.every(({ include }) => include);
    return isInclude;
  };

  const handleCutItem = (
    orderType,
    mainIndex,
    parentProductIndex,
    childProductIndex,
    value,
    orderId
  ) => {
    let data: Array<Record<string, any>> = JSON.parse(
      JSON.stringify([...orderdata])
    );
    data[mainIndex][orderType][parentProductIndex]["product"][
      childProductIndex
    ]["include"] = value;
    data[mainIndex][orderType][parentProductIndex]["product"][
      childProductIndex
    ]["is_pickup_ready"] = value;
    setOrderData([...data]);
  };

  const handleAcceptOrder = async (ele: Record<string, any>, index: number) => {
    setBtnIndex(index);
    setNotifyLoading(true);
    try {
      await storeController
        ?.acceptOrder({ _id: ele?._id, token })
        .then((response: any) => {
          if (response?.status == ApiStatus.STATUS_200)
            toaster?.success({ title: "Order Notified Successfully" });
          else toaster?.warning({ title: "Error accepting order" });
        });
    } catch (error) {
      console.log(error);
      toaster?.warning({ title: `Error accepting order: ${error}` });
    } finally {
      setNotifyLoading(false);
    }
  };

  const handleReadyToPick = async (ele: Record<string, any>, index: number) => {
    setBtnIndex(index);
    setReadyToPickLoading(true);
    const formData = orderdata?.find((e) => e?._id === ele?._id);
    try {
      await storeController
        ?.readyToPickOrder({
          _id: ele?._id,
          body: { ...formData, is_pickup_ready: true },
          token,
        })
        .then((response: any) => {
          if (response?.status == ApiStatus.STATUS_200)
            toaster?.success({ title: "Order Ready to Pick Successfully" });
          else toaster?.warning({ title: "Error ready to pick order" });
        });
    } catch (error) {
      console.log(error);
      toaster?.warning({ title: `Error ready to pick order: ${error}` });
    } finally {
      setReadyToPickLoading(false);
    }
  };

  useEffect(() => {
    if (!storeId) return;
    getStoreProfile();
    getStoreDay();
  }, [storeId, printerId, token]);

  useEffect(() => {
    if (storeId) {
      getStoreDay();
    } else if (STORE_ID) {
      navigate(`/?id=${STORE_ID}&slug=kds`);
    }
  }, [counter, isChecked, token]);

  useEffect(() => {
    if (originalOrderData?.length) {
      const playSound = originalOrderData?.some(({ is_new }) => is_new);
      if (playSound && playSoundBtn?.current) playSoundBtn?.current?.click();
      else sound.pause();
    }
  }, [originalOrderData]);
  
  // const socketOrders = useSocket(`wss://api.loopos.ca?store=${STORE_ID}`);

  // useEffect(() => {
  //   if (!socketOrders) {
  //     console.log("socketOrders is not initialized.");
  //     return;
  //   }
  
  //   console.log("Trying to connect to socketOrders...");
  
  //   // ✅ Log connection status
  //   socketOrders.on("connect", () => {
  //     console.log("✅ Connected to socketOrders!");
  //   });
  
  //   socketOrders.on("connect_error", (err) => {
  //     console.error("❌ Socket connection error:", err.message);
  //   });
  
  //   // ✅ Listen for "kds_order"
  //   socketOrders.on("kds_count", (data: Record<string, any>) => {
  //     console.log("🔥 Received kds_order data:", data);
      
  //   });
  
  //   return () => {
  //     console.log("🔌 Disconnecting socketOrders...");
  //     socketOrders.disconnect();
  //   };
  // }, [socketOrders]);


  
  useEffect(() => {
    socket?.on("kds_order", (data: Record<string, any>) => {
      
      console.log("Received kds_order data:", data); // ✅ Logs every time event triggers
      setOriginalOrderData((prev: Array<Record<string, any>>) => {
        let found = prev?.find(
          (item: Record<string, any>) => item?._id == data?._id
        );
        sound.loop = true;
        sound.play().catch((err) => console.log(err));
        if (!found) {
          filterAccTab({ myData: [...prev, data], name: getCurrentTab() });
          return [...prev, data];
        } else {
          return [...prev];
        }
      });
    });

    socket?.on("kds_update_order", (data: Record<string, any>) => {
      if (data?.is_completed || data?.is_cancelled) {
        setVoidOrders((prev: Array<Record<string, any>>) => {
          return [...prev, data];
        });
        setOriginalOrderData((prev) => {
          let foundIndex = prev?.findIndex(
            (ele: Record<string, any>) => ele?._id == data?._id
          );
          if (foundIndex !== -1) {
            let newData = [
              ...prev?.slice(0, foundIndex),
              ...prev?.slice(foundIndex + 1),
            ];
            filterAccTab({ myData: newData, name: getCurrentTab() });
            return newData;
          }
          return prev;
        });
      } else {
        setOriginalOrderData((prev: any) => {
          let foundIndex = prev?.findIndex(
            (ele: Record<string, any>) => ele?._id == data?._id
          );
          if (foundIndex !== -1) {
            let newData = [
              ...prev?.slice(0, foundIndex),
              data,
              ...prev?.slice(foundIndex + 1),
            ];
            if (data?.merge_order) {
              let mergeOrder = prev?.findIndex(
                (ele: Record<string, any>) => ele?.order_id == data?.merge_order
              );
              if (mergeOrder !== -1) {
                newData?.splice(mergeOrder, 1);
              }
            }
            filterAccTab({ myData: newData, name: getCurrentTab() });
            return newData;
          } else {
            let newData = [...prev, data];
            filterAccTab({ myData: newData, name: getCurrentTab() });
            return newData;
          }
        });
      }
    });

    socket?.on("store_day_end", (data: any) => {
      if (data?.end_day) {
        setOriginalOrderData([]);
        setVoidOrders([]);
      }
    });

    socket?.on("kds_update", (newOrder: any) => {
      setOriginalOrderData((prev: any) => {
        let findIndex = prev?.findIndex(
          (item: any) => item?._id === newOrder?._id
        );
        if (findIndex !== -1) {
          let newData = [
            ...prev?.slice(0, findIndex),
            newOrder,
            ...prev?.slice(findIndex + 1),
          ];
          filterAccTab({ myData: newData, name: getCurrentTab() });
          return newData;
        }
        return prev;
      });
    });

    // socket.on("disconnect", () => {
    //   console.log("Socket disconnected. Attempting to reconnect...");
    //   handleCounter()
    //   // socket.connect(); // Manually trigger socket reconnection
    //   // try {
    //   //   if (!storeId) return;
      
    //   //   // Call functions to get store profile and day
    //   //   getStoreProfile();
    //   //   getStoreDay();
    //   // } catch (error) {
    //   //   console.error("An error occurred:", error); // Logs any errors that occur during execution
    //   // }
    // });
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  if (loading) {
    return (
      <Grid container spacing={1} sx={{ my: 1, px: 2 }}>
        <Shimmer />
      </Grid>
    );
  }
  //  function handleCounter() {
  //     dispatch(setReloadCounter(1));
  //     getStoreProfile();  
  //   }

  return (
    <>
    {appBarShow== true ? <>
       <Box sx={{ textAlign: "center", py: "50px" }}>
              <img src="/storeclose.png" alt="404_img" />
            </Box>
    </>:<>
    <ButtonBar
        activeBtn={handleActive}
        activeNewOldBtn={activeNewCancelCompleteOrders}
        currentStatus={orderType}
        tabBtnStatus={currentTab}
      />
      <Button
        ref={playSoundBtn}
        sx={{ display: "none" }}
        onClick={handlePlaySound}
      ></Button>
      <Grid container spacing={1} mb={5}>
        {orderdata?.map(
          (orderData: Record<string, any>, orderIndex: number) => {
            return (
              <React.Fragment key={orderIndex}>
                {(orderData?.order_type == orderType || orderType == "all") && (
                  <Grid
                    item
                    xs={12}
                    md={
                      gridNum == 2
                        ? 6
                        : gridNum == 3
                        ? 4
                        : gridNum == 4
                        ? 3
                        : gridNum == 6
                        ? 2
                        : gridNum == 8
                        ? 1.5
                        : gridNum
                    }
                  >
                    <Card className="single-order-card">
                      <CardContent
                        sx={{
                          position: "relative",
                          height: "100%",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container spacing={1} className="cardItems">
                          {orderData?.delivery_time !== null && (
                            <Grid item xs={12}>
                              <NewCounter singleOrderData={orderData} />
                            </Grid>
                          )}
                          <Grid item xs={8}>
                            <Typography paragraph={true}>
                              <b>#{orderData?.order_id} </b>&nbsp;
                              <span
                                style={{
                                  color: "#ed6c02",
                                  fontWeight: 600,
                                  fontSize: "18px",
                                }}
                              ></span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign="right">
                            <Typography
                              paragraph={true}
                              className="orderTypeChip"
                            >
                              {textUpperCase(orderData?.order_type)}
                            </Typography>
                            {(orderData?.delivery ||
                              orderData?.order_type == "delivery") && (
                              <Chip
                                label="Delivery"
                                color="success"
                                variant="filled"
                              />
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography paragraph={true} id="order-number">
                              Order Number :
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              paragraph={true}
                              textAlign="right"
                              id="order-number"
                            >
                              {orderData?.order_number}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography paragraph={true}>Customer :</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography paragraph={true} textAlign="right">
                              {orderData?.customer?.name || ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography paragraph={true}>
                              Created At :
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography paragraph={true} textAlign="right">
                              {moment(orderData?.created_at).format(
                                "MMMM Do YYYY, h:mm a"
                              )}
                            </Typography>
                          </Grid>
                          {orderData[orderData?.order_type]?.some(
                            (item) => item?.notes
                          ) && (
                            <>
                              <Grid className="first-container" item xs={4}>
                                <Typography paragraph={true}>Notes:</Typography>
                              </Grid>
                              <Grid className="second-container" item xs={8}>
                                <Typography
                                  paragraph={true}
                                  textAlign={"right"}
                                >
                                  {
                                    orderData[orderData?.order_type]?.find(
                                      (i) => i?.notes
                                    )?.notes
                                  }
                                </Typography>
                              </Grid>
                            </>
                          )}
                          {orderData?.table && (
                            <>
                              <Grid item className="third-container" xs={6}>
                                <Typography
                                  paragraph={true}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  textAlign="right"
                                  columnGap="10px"
                                  id="order-number"
                                >
                                  <TableBarTwoToneIcon
                                    sx={{ fontSize: "22px" }}
                                  />
                                  {orderData?.table?.name || "-"}
                                </Typography>
                              </Grid>
                              <Grid className="fourth-container" item xs={6}>
                                <Typography
                                  paragraph={true}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  textAlign="right"
                                  columnGap="10px"
                                  id="order-number"
                                >
                                  <ChairAltTwoToneIcon
                                    sx={{ fontSize: "22px" }}
                                  />
                                  Seats-
                                  {orderData?.table?.sitting_capacity || "-"}
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          data-cancelled={orderData?.is_cancelled}
                          data-completed={orderData?.is_completed}
                          className="cardItems card-items-container-2"
                        >
                          {(orderData?.take_away?.length ||
                            orderData?.dine_in?.length) && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{ flex: "0 !important", width: "100%" }}
                              >
                                <Typography paragraph={true}>
                                  <b>Order Items </b>:
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                              </Grid>
                              <NewTakeAwayDineIn
                                tabBtnStatus={currentTab}
                                orders={orderData?.take_away || []}
                                element={orderData}
                                title={"Take Away"}
                                mainIndex={orderIndex}
                                foundStatus={orderData?.is_pickup_ready}
                                orderType={orderData?.order_type}
                                handleCutItem={handleCutItem}
                              />
                              <NewTakeAwayDineIn
                                tabBtnStatus={currentTab}
                                orders={orderData?.dine_in || []}
                                title={"Dine In"}
                                element={orderData}
                                mainIndex={orderIndex}
                                foundStatus={orderData?.is_pickup_ready}
                                orderType={orderData?.order_type}
                                handleCutItem={handleCutItem}
                              />
                            </>
                          )}
                          {(orderData?.take_away_void?.length > 0 ||
                            orderData?.dine_in_void?.length > 0) && (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography paragraph={true}>
                                  <b>Voids Order Items </b>:
                                </Typography>
                              </Grid>
                              {orderData?.dine_in_void?.length ? (
                                <TakeAwayVoidDineInVoid
                                  TabBtnStatus={currentTab}
                                  Orders={orderData?.dine_in_void}
                                  Title={"Dine In Void"}
                                  Element={orderData}
                                  FoundStatus={orderData?.is_pickup_ready}
                                />
                              ) : (
                                ""
                              )}
                              {orderData?.take_away_void?.length ? (
                                <TakeAwayVoidDineInVoid
                                  TabBtnStatus={currentTab}
                                  Orders={orderData?.take_away_void}
                                  Title={"Take Away Void"}
                                  Element={orderData}
                                  FoundStatus={orderData?.is_pickup_ready}
                                />
                              ) : (
                                ""
                              )}
                            </Grid>
                          )}
                        </Grid>
                        {currentTab == "newOrder" &&
                          !orderData?.is_pickup_ready && (
                            <Grid container spacing={1}>
                              {orderData?.is_new ? (
                                <Grid item xs={12} textAlign={"center"}>
                                  {btnIndex == orderIndex && notifyloading ? (
                                    <CircularProgress color="warning" />
                                  ) : (
                                    <Button
                                      variant="contained"
                                      className="orderNotifyBtn"
                                      size="large"
                                      color="error"
                                      fullWidth
                                      onClick={() =>
                                        handleAcceptOrder(orderData, orderIndex)
                                      }
                                    >
                                      <VisibilitySharpIcon /> &nbsp;
                                      <b>Notify Order</b>
                                    </Button>
                                  )}
                                </Grid>
                              ) : (
                                <Grid item xs={12} textAlign={"center"}>
                                  {btnIndex == orderIndex &&
                                  readyToPickLoading ? (
                                    <CircularProgress color="warning" />
                                  ) : checkIfNewOrder(orderData) ? (
                                    <Button
                                      variant="contained"
                                      className="readyToPickBtn"
                                      size="large"
                                      color="warning"
                                      fullWidth
                                      onClick={() =>
                                        handleReadyToPick(orderData, orderIndex)
                                      }
                                    >
                                      <AlarmOnSharpIcon /> &nbsp;
                                      <b>Ready to pick</b>
                                    </Button>
                                  ) : null}
                                </Grid>
                              )}
                            </Grid>
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </React.Fragment>
            );
          }
        )}
      </Grid>
    </>
    
    
    }
     
      
      {/* <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          padding: "10px",
          textAlign: "center",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
          zIndex: 1000,
        }}
      >
         <div>
      
    </div>
    <p>Socket Orders Connected: {socketOrders?.connected ? "Yes" : "No"}</p>
        <Typography variant="h6">{count?.map((data,index)=>{
          return(<>
                  {`${data?.product?.name}(${data?.quantity})`}
          </>)
        })}</Typography>
      </Box> */}
    </>
  );
};

export default NewHome;
