import { ApiURL } from "../../api/api_url.ts";
import { POST, GET, PUT, DELETE, ApiResponseClass } from "../../api/api_methods.ts";
import { GConstants } from "../../helper/g_constants.ts";

export class StoreController {
  private async makeGetRequest(url: string, token: string): Promise<ApiResponseClass> {
    return await GET({
      url,
      headers: {
        [GConstants.AUTHORIZATION]: `${GConstants.BEARER} ${token}`,
      },
    });
  }

  // API FOR GET STORE DETAIL
  async getStoreDetails({ _id, token }: { _id: any, token: string }): Promise<ApiResponseClass> {
    return await this.makeGetRequest(ApiURL.STORE_PROFILE_URL + _id, token);
  }

  // API FOR STORE DAY ID
  async getStartDay({ _id, token }: { _id: any, token: string }): Promise<ApiResponseClass> {
    return await this.makeGetRequest(ApiURL.STORE_START_DAY_URL + _id, token);
  }

  // API FOR ALL STORE ORDERS
  async getStoreOrders({ day_id, store_Id, old, token }: { day_id: any; store_Id: any, old: boolean, token: string }): Promise<ApiResponseClass> {
    const url = `${ApiURL.GET_STORE_ORDERS_URL}${day_id}&store=${store_Id}${old ? '&type=old' : ''}`;
    return await this.makeGetRequest(url, token);
  }

  // API FOR CANCEL ORDERS
  async getStoreVoidOrders({ day_id, store_Id, token }: { day_id: any; store_Id: any, token: string }): Promise<ApiResponseClass> {
    const url = ApiURL.GET_VOID_ORDERS_URL + day_id + "&store=" + store_Id;
    return await this.makeGetRequest(url, token);
  }

  // ACCEPT ORDER OR TAKE ORDER BY CHEF
  async acceptOrder({ _id, token }: { _id: string, token: string }) {
    return await PUT({
      url: ApiURL.GET_ACCEPT_ORDERS_URL + _id,
      headers: {
        [GConstants.AUTHORIZATION]: `${GConstants.BEARER} ${token}`,
      },
    });
  }

  // READY TO PICK ORDER
  async readyToPickOrder({ _id, body, token }: { _id: string; body: Record<string, any>, token: string }) {
    return await PUT({
      url: ApiURL.READY_TO_PICK_ORDER_URL + _id,
      body,
      headers: {
        [GConstants.AUTHORIZATION]: `${GConstants.BEARER} ${token}`,
      },
    });
  }

  async getPrintersList({ store_Id, token }: { store_Id: string | null, token: string }): Promise<ApiResponseClass> {
    return await this.makeGetRequest(`${ApiURL.GET_PRINTERS_LIST_URL}/${store_Id}`, token);
  }

  // API FOR LOGIN
  async login({ email, password }: { email: string, password: string }): Promise<ApiResponseClass> {
    return await POST({
      url: ApiURL.LOGIN_URL,
      body: { email, password },
    });
  }
}
