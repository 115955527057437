import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import OrderSlice from "./Slices/OrderSlice";
import StoreSlice from "./Slices/StoreSlice";
import NotifyOrdersSlice from "./Slices/OrderNotify";
import PrintersSlice from "./Slices/PrintersSlice";
import CheckboxSlice from "./Slices/CheckboxSlice";
import userReducer from './Slices/UserSlice';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

const params = new URLSearchParams(window.location.search);
const date = new Date();
const string = `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`; 
const persistConfig = {
  key: `root-${string}`,
  storage,
};

const rootReducer = combineReducers({
  orders: OrderSlice,
  store: StoreSlice,
  notifyOrders: NotifyOrdersSlice,
  printers: PrintersSlice,
  checkbox: CheckboxSlice,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ],
});