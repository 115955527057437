import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import { setAllOrder, setGridNum } from "./Redux_Store/Slices/OrderSlice";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Fab,
  MenuItem,
  Select,
  Button,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ApiStatus } from "../api/api_url.ts";
import CachedIcon from "@mui/icons-material/Cached";
import { setReloadCounter } from "./Redux_Store/Slices/StoreSlice";
import { useNavigate } from "react-router-dom";
import { StoreController } from "./Controllers/store_controller.tsx";
function Header({ activeBtnName, StoreId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeController = new StoreController();
  const totalOrders = useSelector((state: any) => state.orders.totalOrder);
  const totalVoidOrder = useSelector((state: any) => state.orders.allVoidOrder);
  // const cancelOrders = useSelector((state: any) => state.orders.cancelOrder);
  const completeOrders = useSelector(
    (state: any) => state.orders.completeOrder
  );
  const GridNumber = useSelector((state: any) => state.orders?.gridNum);
  const [accessDenied, setAssessDenied] = useState<boolean>();
  async function getStoreProfile() {
    let response = await storeController.getStoreDetails({ _id: StoreId });
    if (response.status == ApiStatus.STATUS_200) {
      setAssessDenied(
        response.data.data.store_setting.general_setting.kds_status
      );
      if (response.data.data.store_setting.general_setting.kds_status) {
        navigate(`/?id=${StoreId}&slug=kds`);
      } else {
        navigate(`/access-denied`);
      }
    }
  }

  function handleCounter() {
    dispatch(setReloadCounter(1));
    getStoreProfile();
  }
  return (
    <>
      <Card className="cardDesign">
        <CardContent style={{ paddingBottom: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={8} md={3} lg={2}>
              <img src="/logo/loop_logo.gif" alt="logo" width={160} />
            </Grid>
            <Grid item xs={6} sm={8} md={6} lg={8.5} textAlign={"center"}>
              {accessDenied && (
                <Typography variant="h6" color="warning" fontFamily={"poppins"}>
                  {activeBtnName == "newOrder"
                    ? `Running Orders :  ${totalOrders}`
                    : activeBtnName == "voidOrder"
                    ? `Void Orders :  ${totalVoidOrder}`
                    : activeBtnName == "readyToPick"
                    ? `Ready To Pick Orders :  ${totalOrders}`
                    : activeBtnName == "completeOrder"
                    ? `Complete Orders :  ${completeOrders}`
                    : ""}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={1}
              lg={0.5}
              sx={{ textAlign: { xs: "left", md: "center" } }}
            >
              <Fab color="warning" size="small" onClick={handleCounter}>
                <CachedIcon sx={{ fontSize: "20px" }} />
              </Fab>
            </Grid>
            <Grid item xs={10} sm={3} md={2} lg={1}>
              <FormControl
                color="error"
                size="small"
                fullWidth
                sx={{
                  fontFamily: "poppins",
                }}
              >
                <InputLabel color="error" id="demo-simple-select-label">
                  View Layout
                </InputLabel>
                <Select
                  size="small"
                  value={GridNumber}
                  color="warning"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="View Layout"
                  onChange={(e) => {
                    dispatch(setGridNum(e.target.value));
                  }}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Outlet />
    </>
  );
}

export default Header;
