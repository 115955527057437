import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import moment from 'moment-timezone';
import { useSelector } from "react-redux";
import AlarmIcon from "@mui/icons-material/Alarm";


const Counter = React.memo(({ singleOrderData }: any) => {
  const timeZone = useSelector(
    (state: Record<string, any>) =>
      state.store.storeDetail.store_setting.general_setting.time_zone.time_zone
  );
  const [countDownTime, setCountDownTime] = useState(10);
  const [twentyFifePer, setTwentyFifePer] = useState(0);
  const [readyBy, setReadyBy] = useState("");
  const [fiftyPer, setFiftyPer] = useState(0);
  const timerExecute = (producData: any) => {
    const { track_order, delivery_time: deliveryTime } = producData;
    const preparingOrder = track_order.find(
      (track: any) => track.status === "preparing"
    );
    const cancelledOrder = track_order.some(
      (track: any) =>
        track.status === "cancelled" ||
        track.status === "ready_to_pick_up" ||
        track.status === "completed"
    );
    if (preparingOrder) {
      const startTime = moment(preparingOrder.time, "YYYY-MM-DD HH:mm:ss");
      const currentTime = moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
      const endTime = moment(currentTime, "YYYY-MM-DD HH:mm:ss");
      const diffInMilliseconds = endTime.diff(startTime);
      const diffInSeconds = diffInMilliseconds / 1000;
      const seconds = deliveryTime * 60 - diffInSeconds;
      //START THIS CODE GET MILISECOUNDS AND SET INTO TIME ZONE - TIME AND CREATE READY BY TIME
      const millisecond = deliveryTime * 60 * 1000;
      setReadyBy(
        moment(startTime.set("millisecond", millisecond)).format("HH:mm a")
      );

      //END CODE
      setFiftyPer(Math.floor(((deliveryTime * 60) / 100) * 50));
      setTwentyFifePer(Math.floor(((deliveryTime * 60) / 100) * 25));

      if (cancelledOrder) {
        setCountDownTime(0);
      } else if (seconds > 0) {
        setCountDownTime(seconds);
      } else {
        setCountDownTime(0);
      }
    } else {
      setCountDownTime(0);
    }
  };

  const FixedTime = ` 
        ${+readyBy.split(":")[0] >= 12
      ? `${+readyBy.split(":")[0] - 12} `.padStart(3, "0")
      : readyBy.split(":")[0]
    }: ${readyBy.split(":")[1]}`;
  useEffect(() => {
    let timer = setInterval(() => {
      setCountDownTime((time: any) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countDownTime]);

  useEffect(() => {
    timerExecute(singleOrderData);
  }, [singleOrderData]);

  const getColor = (
    countDownTime: number,
    fiftyPer: number,
    twentyFifePer: number
  ) => {
    if (countDownTime < fiftyPer || countDownTime === 0) {
      if (countDownTime < twentyFifePer) {
        return "#CF000F";
      }
      return "#F9690E";
    } else {
      return "#26C281";
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py={0.5}
      px={2}
      mb={1}
      borderRadius={2}
      alignItems="center"
      flexWrap="wrap"
      bgcolor={(theme) => getColor(countDownTime, fiftyPer, twentyFifePer)}
      color={(theme) => theme.palette.primary.contrastText}
    >
      <Box>
        <Typography variant="h5" className="counterDateTimer">
          {`${Math.floor(countDownTime / 60)}`.padStart(2, "0")}:
          {`${countDownTime % 60}`.padStart(2, "0")}
          {/* <AlarmIcon sx={{ fontSize: "35px" }} /> */}
        </Typography>
      </Box>
      <Typography variant="h5" className="counterDateTimer">
        {FixedTime}
      </Typography>
    </Box>
  );
});

export default Counter
