import { useMemo } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';

export const SocketIO = () => {
  const selectedPrinter = useSelector((state: any) => state.printers.selectedPrinter);
  const isChecked = useSelector((state: any) => state.checkbox.isChecked);
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get('id');
  // console.log(`${isChecked ? `wss://worker.loopos.ca?store=${storeId}` : `wss://api.loopos.ca?store=${selectedPrinter}`} ==========SOKECT URL==========`);

  const socket = useMemo(() => io(`${isChecked ? `wss://worker.loopos.ca?store=${storeId}` : `wss://api.loopos.ca?store=${selectedPrinter}`}`, {
    transports: ["websocket"],
    autoConnect: true,
  }), [selectedPrinter, isChecked, storeId]);
  return socket;
};
export default SocketIO;
