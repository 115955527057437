import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import { setAllOrder, setGridNum } from "./Redux_Store/Slices/OrderSlice";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Fab,
  MenuItem,
  Select,
  Button,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ApiStatus } from "../api/api_url.ts";
import CachedIcon from "@mui/icons-material/Cached";
import { setReloadCounter } from "./Redux_Store/Slices/StoreSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreController } from "./Controllers/store_controller.tsx";
import { GToaster } from "../helper/g_toaster.tsx";
import { setPrinterList, setSelectedPrinter } from "./Redux_Store/Slices/PrintersSlice";
import { SocketIO } from "./Controllers/socket.tsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { clearUser } from "./Redux_Store/Slices/UserSlice.js";

function Header({ activeBtnName, StoreId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const storeController = new StoreController();
  const gToaster = new GToaster();
  const totalOrders = useSelector((state: any) => state.orders.totalOrder);
  const totalVoidOrder = useSelector((state: any) => state.orders.allVoidOrder);
  const printersList = useSelector((state: any) => state.printers.printersList);
  const selectedPrinter = useSelector((state: any) => state.printers.selectedPrinter);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get("id");
  const { search } = useLocation();
  const token = useSelector((state: any) => state.user.access_token);
  const userData = useSelector((state: any) => state.user.user_data);
  const completeOrders = useSelector((state: any) => state.orders.completeOrder);
  const GridNumber = useSelector((state: any) => state.orders?.gridNum);
  const userLoggedIn = useSelector((state: any) => state.user?.access_token);
  const [accessDenied, setAssessDenied] = useState<boolean>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const socket = SocketIO();

  async function getStoreProfile() {
    if (!storeId || !token) return;
    let response = await storeController.getStoreDetails({ _id: storeId, token });
    if (response.status == ApiStatus.STATUS_200) {
      setAssessDenied(
        response.data.data.store_setting.general_setting.kds_status
      );
      if (response.data.data.store_setting.general_setting.kds_status) {
        navigate(`/?id=${StoreId}&slug=kds`);
      } else {
        navigate(`/access-denied`);
      }
    }
  }

  function handleCounter() {
    dispatch(setReloadCounter(1));
    getStoreProfile();
  }

  async function getPrintersList() {
    let response = await storeController.getPrintersList({ store_Id: storeId, token });
    if (response.status == ApiStatus.STATUS_200) {
      dispatch(setPrinterList(response.data.data));
      selectedPrinter || dispatch(setSelectedPrinter(response.data.data[0]?._id));
    } else if (response.status == ApiStatus.STATUS_500) {
      gToaster.warning({ title: "500 Server Error" });
    } else {
      gToaster.warning({ title: "Server Error" });
    }
  }

  useEffect(() => {
    getPrintersList();
    getStoreProfile();
    setIsLoggedIn(userLoggedIn);
  }, [token, storeId])

  const handlePrinterChange = (printerId: string) => {
    socket.disconnect();
    dispatch(setSelectedPrinter(printerId));
    socket.connect();
  }

  return (
    <>
      <Card className="cardDesign">
        <CardContent style={{ paddingBottom: "15px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={2.5} textAlign={{ xs: "center", sm: "left" }}>
              <img src="/logo/loop_logo.gif" alt="logo" width={160} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} textAlign="center">
              {accessDenied && (
                <Typography variant="h6" color="warning" fontFamily={"poppins"}>
                  {activeBtnName == "newOrder"
                    ? `Running Orders :  ${totalOrders}`
                    : activeBtnName == "voidOrder"
                      ? `Void Orders :  ${totalVoidOrder}`
                      : activeBtnName == "readyToPick"
                        ? `Ready To Pick Orders :  ${totalOrders}`
                        : activeBtnName == "completeOrder"
                          ? `Complete Orders :  ${completeOrders}`
                          : ""}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sm={1} md={1} textAlign="center">
              <Fab color="warning" size="small" onClick={handleCounter}>
                <CachedIcon sx={{ fontSize: "20px" }} />
              </Fab>
            </Grid>
            {location.pathname !== '/home' && (
              <Grid item xs={12} sm={6} md={2} textAlign="center">
                <FormControl
                  color="error"
                  size="small"
                  fullWidth
                  sx={{ fontFamily: "poppins" }}
                >
                  <InputLabel color="error" id="demo-simple-select-label">
                    Printers List
                  </InputLabel>
                  <Select
                    size="small"
                    value={selectedPrinter || printersList[0]?._id || ''}
                    color="warning"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="View Layout"
                    onChange={(e) => handlePrinterChange(e.target.value)}
                  >
                    {printersList.map((printer, index) => (
                      <MenuItem key={index} value={printer._id}>{printer.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              <FormControl
                color="error"
                size="small"
                fullWidth
                sx={{ fontFamily: "poppins" }}
              >
                <InputLabel color="error" id="demo-simple-select-label">
                  View Layout
                </InputLabel>
                <Select
                  size="small"
                  value={GridNumber}
                  color="warning"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="View Layout"
                  onChange={(e) => {
                    dispatch(setGridNum(e.target.value));
                  }}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              {isLoggedIn && (
                <FormControl
                  color="error"
                  size="small"
                  fullWidth
                  sx={{ fontFamily: "poppins" }}
                >
                  <InputLabel color="error" id="user-select-label">
                    User
                  </InputLabel>
                  <Select
                    size="small"
                    value={userData._id} // Replace with actual username state
                    color="warning"
                    labelId="user-select-label"
                    id="user-select"
                    label="User"
                  >
                    <MenuItem value={userData._id}>{userData.name}</MenuItem>
                    <MenuItem value="logout" onClick={() => {
                      setIsLoggedIn(false);
                      dispatch(clearUser());
                      navigate(`/login${search}`); // Redirect to login page
                    }}>Logout</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Outlet />
    </>
  );
}

export default Header;