import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_data: null,
  access_token: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user_data = action.payload.userData;
      state.access_token = action.payload.token;
    },
    clearUser: (state) => {
      state.user_data = null;
      state.access_token = null;
    },
  },
});

export const { setUser, clearUser, setToken } = userSlice.actions;
export default userSlice.reducer;
